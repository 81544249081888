import React from 'react'
import { Link } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap';

import { ArrowRight } from 'react-bootstrap-icons';

import Image from '../image';

export default ({ title, content }) => {

    return(
        <section className="section">
            <Container>
                <Row>
                    <Col md={6}>
                        <h1 className="featurette-heading mb-4">{title}</h1>
                        <p className="lead mb-4">{content}</p>
                        <p>
                            <Link to="about-mekei" className="btn btn-primary btn-navbar-custom">
                                Дэлгэрэнгүй <ArrowRight />
                            </Link>
                        </p>
                    </Col>
                    <Col md={6}>
                        <Image src="about.jpg" alt="Hero Image" />
                    </Col>
                </Row>
            </Container>
        </section>
    )

}