import React from 'react'

import SEO from '../components/seo'
import Layout from '../components/layout'

import Hero from '../components/views/hero'
import Events from '../components/views/events'
import FeatureLeft from '../components/views/feature-left'
import TextWithVideo from '../components/views/text-with-video'

export default function Home() {
  return (
    <Layout>
      <SEO title="" />
      <Hero />
      <TextWithVideo />
      <FeatureLeft
        title="Абишийн Мекей багшийн тухай"
        content="А.Мекей багш бол 1940 онд Баян-Өлгий аймгийн Толбонуур сумын нутагт төрсөн, 1949-1959 онд Баян-Өлгий аймгийн 1-р арван жилийн дунд сургууль, 1959-1963 онд МУИС-ийн математикийн салбарыг..."
      />
      <Events />
    </Layout>
  )
}
