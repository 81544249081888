import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { Container, Row, Col } from 'react-bootstrap';
import { ArrowRightShort } from 'react-bootstrap-icons';

import Image from '../image';

export default () => {
    const data = useStaticQuery(graphql`
        query {
            allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }, limit: 3) {
                edges {
                    node {
                        id
                        frontmatter {
                            title
                            image
                            date(formatString: "DD MMMM, YYYY")
                        }
                        fields {
                            slug
                        }
                        excerpt
                    }
                }
            }
        }
    `)

    return (
        <>
        <style>{`
            .blog-border-top {
                border-top: solid 1px #E6DDD6;
            }
            .blog-excerpt { 
                font-size: 20px;
                font-weight: 300;
            }
            .blog-image {
                border-radius: 2px;
            }
        `}</style>
        <section className="section blog-border-top">
            <Container>
                <Row>
                    <div className="col text-center">
                        <h2 className="section-title mb-5">Мэдээ, үйл явдал</h2>
                    </div>
                </Row>
                <Row>
                    {data.allMarkdownRemark.edges.map(({ node }, index) => {
                        return (
                            <Col md={4} key={index}>
                                <div className="mb-4">
                                    <Link to={node.fields.slug}>
                                        <Image className="blog-image" src={`events/${node.frontmatter.image}`} alt={node.frontmatter.title} />
                                    </Link>
                                </div>
                                <p>{node.frontmatter.date}</p>
                                <h3 className="mb-4">{node.frontmatter.title}</h3>
                                <p className="mb-4 blog-excerpt">{node.excerpt}</p>
                                <p className="mb-0">
                                    <Link to={node.fields.slug}>
                                        Дэлгэрэнгүй <ArrowRightShort />
                                    </Link>
                                </p>
                            </Col>
                        )
                    })}
                </Row>
            </Container>
        </section>
        </>
    )
}