import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';

export default () => {
    return(
        <section className="section border-bottom">
            <Container>
                <Row>
                    <Col className="col-md-12 text-center">
                        <blockquote className="blockquote text-center mb-5">
                            <h2 className="mb-0">"Багшийн намтар, түүний математикийн <br />шинжлэх ухааны замналын тухай товч видео"</h2>
                            <footer className="blockquote-footer">Ардын багш <cite title="Source Title">Абишийн Мекей</cite></footer>
                        </blockquote>
                    </Col>
                </Row>
                <Row>
                    <Col md={{ span: 8, offset: 2 }} className="text-center">
                        <div className="video-container">
                            <iframe title="Mekei Video" className="video" width="560" height="315" src="https://www.youtube.com/embed/nFpflVOxqWk" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}