import React from 'react'
import { Link } from 'gatsby'

import { Jumbotron, Container, Row, Col } from 'react-bootstrap';
import { ArrowRight } from 'react-bootstrap-icons';

import Image from '../image';

export default () => {
    return (
        <>
            <style type="text/css">
            {`
                .jumbotron-hero {
                    min-height: 100vh;
                    width: 90%;
                    position: relative;
                    color: #fff;
                    margin: 0 auto;
                    margin-bottom: 0;
                    overflow: hidden;
                }
                .jumbotron-hero .container {
                    position: absolute;
                    left: 0;
                    right: 0;
                    margin-left: auto;
                    margin-right: auto;
                    z-index: 3;
                }
                .jumbotron-title {
                    font-size: 60px;
                    font-weight: bold;
                    text-shadow: 0px 1px #000;
                    line-height: 1.1;
                    letter-spacing: 1px;
                }
                .jumbotron-description {
                    font-size: 24px;
                    padding: 30px 0;
                    text-shadow: 0px 1px #000;
                    line-height: 1.2;
                    letter-spacing: 1px;
                }
                .dark-bg {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: rgba(0,0,0,.4);
                    z-index: 2;
                }
                .hero-img {
                    position: absolute;
                    top: 0;
                    height: 100%;
                    z-index: 1;
                    overflow: hidden;
                }
                .hero-img img {
                    width: 100%;
                }
                @media(min-width: 768px) {
                    .hero-img {
                        left: 0;
                        width: 100%;
                    }
                    .jumbotron-hero .container {
                        top: 30%;
                    }
                }
                @media(max-width: 767px) {
                    .jumbotron-hero {
                        width: 100%;
                    }
                    .hero-img {
                        left: -50%;
                        width: 300%;
                    }
                    .jumbotron-hero .container {
                        top: 30%;
                    }
                    .jumbotron-title {
                        font-size: 30px;
                    }
                    .jumbotron-description {
                        font-size: 20px;
                    }
                }
            `}
            </style>
            <Jumbotron className="jumbotron-hero">
                <div className="dark-bg"></div>
                <div className="hero-img">
                    <Image src="s2.jpg" alt="Hero Image" />
                </div>
                <Container>
                    <Row>
                        <Col>
                            <h1 className="jumbotron-title">Ардын багш, академич, шинжлэх ухааны доктор, профессор Абишийн Мекей</h1>
                            <p className="jumbotron-description">
                                Мекей сан нь 2014 оноос хойш баруун <br />бүсийн Математикийн олимпиадыг <br />санаачлан хэрэгжүүлж байгаа бөгөөд <br />дараах чиглэлүүдээр үйл ажиллагаагаа <br />өрөгжүүлэн тэлсээр байна.
                            </p>
                            <p>
                                <Link to={`/about-mekei-foundation`} className="btn btn-primary btn-lg btn-custom">Мекей сангийн тухай <ArrowRight /></Link>
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Jumbotron>
        </>
    )
}